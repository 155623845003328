import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'antd'

import { useAppSelector, useQuery } from '../../app/hooks'
import { Datatable, DataTableTotal, tokenTransferColumns } from '../../common'
import { TokenDetailsType } from '../../constants/types'
import { getVrc2021TransferList } from './tokensSlice'
import { SearchOutlined } from '@ant-design/icons'



import {
  InputStyled,
  PositionStyled
} from './tokenDetailsPageStyles'
import { fungibleTokenStandard } from '../../constants'

interface Vrc2021TransferListProps {
  token: TokenDetailsType
}

export default function TokenDetailsTransferList({ token }: Vrc2021TransferListProps) {
  const query = useQuery()
  const getAccount = query.get('account')?.trim() || ''
  const [account, setAccount] = useState(getAccount)
  
  const history = useHistory()
  const { transfers } = useAppSelector((state) => state.tokens.details)

  const getData = useCallback(
    (params: any) => getVrc2021TransferList({ ...params, address: token.address,account }),
    [account]
  )
  
  const onFinish = async (values: any) => {
    setAccount(values.address)
    if (values.address) {
      history.push({ search: `?account=${values.address}` })
    } else {
      history.push({ search: '' })
    }
  }
  const stateType = fungibleTokenStandard.includes(token.type) ? 'vrc2021' : 'vrc721'
  const { data, total, loading } = transfers[stateType]

  return (
    <>
      <PositionStyled>

        <Form layout="vertical" style={{ display: "flex" }} onFinish={onFinish} autoComplete="off">
          <Form.Item
            name="address"
          >
            <InputStyled
              allowClear={true}
              placeholder="Filter Address"
            />
          </Form.Item>
          <Form.Item>
            {/* <ButtonSubmit
              type="submit"
              value="Search"
            /> */}
            <button type='submit' className='button-custom'>
              <SearchOutlined/>
            </button>
          </Form.Item>
        </Form>
      </PositionStyled>
      <Datatable
        loading={loading}
        total={total}
        columns={tokenTransferColumns}
        dataSource={data}
        header={<DataTableTotal total={total} type="tx" />}
        getData={getData}
        />
    </>
  )
}