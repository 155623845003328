import { useState, useEffect, FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
    Tabs,
    Row,
    Col,
    Spin,
    Tooltip,
    Form,
    Input,
} from 'antd'
import {
    CodeFilled,
    OrderedListOutlined,
    CodeTwoTone,
    HeatMapOutlined,
    FileTextOutlined
} from '@ant-design/icons'
import {
    NoteInfo,
    TitleHeaderBox,
    TxtColStyle,
    TextOverlay,
    ContractSource,
    SwitchButton,
    Cards,
    CardHeader,
    CardContent,
    TextMain,
    FormGroup,
    FormItem,
    ButtonQuery,
    LinkStyled,
    StyleCopyIcon
} from './contractDetailsPageStyles'

import { getContract } from '../../utils'
import { ModalWallet } from '../../common'
import iconCopy from '../../assets/images/icon/icon-copy.svg'
import icNothing from '../../assets/images/status/warning.svg';
import Text from 'antd/lib/typography/Text'
import CheckStatus from '../../common/CheckStatus'



const { TabPane } = Tabs
type ContractState = {
    data: any
    address: string
}


type DataFormState = {
    data: any
    name: string
    contract: any
}

const FormReadRender: FunctionComponent<DataFormState> = ({ data, name, contract }) => {
    const [dataResult, setDataResult]: Array<any> = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const onReadFinish = async (value: any) => {
        let formatData: Array<any> = []
        setIsLoading(true)
        const params = Object.values(value)
        // Convert data with type []
        const convertParams = params.map((item: any) => {
            if (item.startsWith('[') && item.endsWith(']')) {
                item = JSON.parse(item)
            }
            return item
        })
        try {
            const result = await contract.methods[name](...convertParams).call()
            for (let i = 0; i < data.outputs.length; i++) {
                const output = data.outputs[i]
                const outputRs = output
                let value
                if (typeof result === 'object' && !Array.isArray(result)) {
                    value = result[output.name]

                    if (value !== 'undefined') {
                        value = result[i]
                    }
                } else if (Array.isArray(result)) {
                    value = JSON.stringify(result)
                } else {
                    value = result
                }
                if (output.type === 'address') {
                    if (value === 0) {
                        value = '0x0000000000000000000000000000000000000000'
                    }

                    value = value.toLowerCase()
                }

                outputRs.value = value
                formatData.push(outputRs)
            }

        } catch (error: any) {
            formatData.push({
                name: 'Error',
                type: 'string',
                value: error.message
            })
        }
        setDataResult(formatData)
        setIsLoading(false)
    }
    return (
        <Form onFinish={onReadFinish}>
            {
                data.inputs.map((input: any, i: number) => {
                    return (
                        <FormGroup key={`rf-content-${i}`}>
                            <TextMain>{`${input.name} (${input.type})`}</TextMain>
                            <FormItem
                                name={input.name ? input.name : name + i}
                            >
                                <Input
                                    placeholder={`${input.name} (${input.type})`}
                                />
                            </FormItem>
                        </FormGroup>
                    )
                })
            }
            <ButtonQuery htmlType="submit" disabled={isLoading} style={{ marginTop: "20px" }}>
                <TextMain>Query</TextMain>
            </ButtonQuery> &nbsp;
            {isLoading && <Spin size="small" />}
            <div style={{ marginTop: '20px' }}>
                {dataResult.length > 0 && <TextMain>[<b>{name}</b> method response]</TextMain>}
                {dataResult.map((d: any, k: number) => {
                    return (
                        <div key={`r-${k}`} className={d.name === 'Error' ? 'error' : 'success'}>
                            {d.name && <TextMain style={{ marginRight: '10px' }}>{d.name}</TextMain>}
                            {<TextOverlay style={{ fontStyle: 'italic' }}>{d.type.toString()}:</TextOverlay>} &nbsp;
                            {d.value !== null ? <TextMain>{d.value.toString()}</TextMain> : <TextMain>null</TextMain>} &nbsp;
                        </div>
                    )
                })
                }
            </div>
        </Form>
    )
}

type ReadOutputState = {
    outputs: any
    name: string
    contract: any
}
const ReadDataOutput: FunctionComponent<ReadOutputState> = ({ outputs, name, contract }) => {
    const [data, setData] = useState()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await contract.methods[name]().call()
                setData(result)
            } catch (error) {
                console.log(error)
            }
        }
        fetchData()
    }, [])

    function isObject(val: any) {
        return val instanceof Object
    }
    return (
        <>
            {
                outputs.map((s: any, j: Number) => {
                    return (
                        <div key={`o-content-${j}`}>
                            {s.type.indexOf('[]') !== -1 ?
                                // @ts-ignore
                                Array.isArray(data) && data.map((d: any, i: number) => {
                                    return (
                                        s.type.indexOf('address') !== -1 ?
                                            <div key={`address-${i}`}>
                                                <Link to={`/address/${d}`}>{d}</Link> <br />
                                            </div>
                                            : <TextMain key={`output-${i}`}>{d}</TextMain>
                                    )
                                })
                                : isObject(data) ?
                                    <>
                                        <TextOverlay>{s.name}:</TextOverlay>&nbsp;
                                        <TextMain>
                                            {// @ts-ignore
                                                data[s.name]
                                            }
                                        </TextMain>
                                    </>
                                    :
                                    <>
                                        {s.type === 'address' ?
                                            <Link to={`/address/${data}`}>{data}</Link>
                                            : <TextMain>{data}</TextMain>
                                        }
                                    </>
                            }
                            < TextOverlay style={{ fontStyle: 'italic', fontSize: '13px', }}> {s.type}</TextOverlay>
                        </div>
                    )
                })
            }
        </>
    )
}

type DataWriteForm = {
    data: any
    name: string
    abiCode: any
    contractAddress: string
    account: string
    isConnected: boolean
}
const FormWriteRender: FunctionComponent<DataWriteForm> = ({ data, name, abiCode, contractAddress, account, isConnected }) => {
    const [dataResult, setDataResult]: Array<any> = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const onWriteFinish = async (value: any) => {
        let formatData: Array<any> = []
        setIsLoading(true)
        const payableValue = value['payable-value'] ?? null
        // removing payable value from params
        delete value['payable-value']
        const params = Object.values(value)
        const convertParams = params.map((item: any) => {
            if (item.startsWith('[') && item.endsWith(']')) {
                item = JSON.parse(item)
            }
            return item
        })
        try {
            if (window.web3 && account) {
                const contract = new window.web3.eth.Contract(abiCode, contractAddress)
                let sendData: any
                sendData = {
                    from: account,
                    gasLimit: '0x7A120', // 1000_000
                    value: payableValue ? payableValue : null,
                }
                const estGas = await contract.methods[name](...convertParams).estimateGas(sendData)
                if (estGas) {
                    sendData.gasLimit = estGas
                }
                const result = await contract.methods[name](...convertParams).send(sendData)
                formatData.push(
                    {
                        name: 'View your block',
                        type: 'blockNumber',
                        value: result.blockNumber ? result.blockNumber : ''
                    },
                    {
                        name: 'View your transaction',
                        type: 'tx',
                        value: result.transactionHash ? result.transactionHash : ''
                    }
                )
            }
        } catch (error: any) {
            formatData.push({
                name: 'Error',
                type: 'string',
                value: error.message
            })
        }
        setDataResult(formatData)
        setIsLoading(false)
    }
    return (
        <Form onFinish={onWriteFinish}>
            {data.stateMutability === 'payable' &&
                <FormGroup key={`wf-content-payable-value`}>
                    <TextMain>{`payable value (wei)`}</TextMain>
                    <FormItem
                        name='payable-value'
                    >
                        <Input placeholder={`payableValue (uint256)`} />
                    </FormItem>

                </FormGroup>
            }
            {

                data.inputs.map((input: any, i: number) => {
                    return (
                        <FormGroup key={`wf-content-${i}`}>
                            <TextMain>{`${input.name} (${input.type})`}</TextMain>
                            <FormItem
                                name={input.name ? input.name : name + i}
                            >
                                <Input placeholder={`${input.name} (${input.type})`} />
                            </FormItem>

                        </FormGroup>
                    )
                })
            }
            <ButtonQuery htmlType="submit" ><TextMain>Write</TextMain></ButtonQuery> &nbsp; {isLoading && <Spin size="small" />}
            <div style={{ marginTop: '20px' }}>
                {dataResult.length > 0 && <div><TextMain>[<b>{name}</b> method response]</TextMain></div>}
                {dataResult.map((d: any, k: number) => {
                    return (
                        d.name === 'Error' ?
                            <div key={`w-${k}`} className='error'>
                                {<TextMain style={{ marginRight: '10px' }}>{d.name}</TextMain>}
                                {<TextOverlay style={{ fontStyle: 'italic' }}>{d.type.toString()}:</TextOverlay>}
                                {<TextMain>{d.value.toString()}</TextMain>} &nbsp;
                            </div>
                            :
                            <LinkStyled to={d.type === 'blockNumber' ? `/block/${d.value}` : `/tx/${d.value}`}>{d.name}</LinkStyled>
                    )
                })
                }
            </div>
        </Form>
    )
}

const SolidityRender = ({ data }: any) => {
    const [copied, setCopied] = useState(false)

    return (
        <>
            <TitleHeaderBox style={{ marginTop: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div><CodeFilled /> {data[0]} </div>
                <Tooltip placement="top" title={copied ? "Copied" : "Copy to clipboard"}>
                    <CopyToClipboard
                        text={data[1].content}
                        onCopy={() => setCopied(true)}
                        //@ts-ignore
                        onMouseEnter={() => setCopied(false)}
                    >
                        <StyleCopyIcon style={{ marginRight: '10px' }}><span><img width="20" src={(iconCopy)} alt="icon copy" /></span></StyleCopyIcon>
                    </CopyToClipboard>
                </Tooltip>
            </TitleHeaderBox>
            <ContractSource height="20pc">
                {data[1].content}
            </ContractSource>
        </>
    )
}

const ContentOfTheContractTab = ({ data, address }: ContractState) => {
    const [copied, setCopied] = useState(false)
    const [isByteCode, setIsByteCode] = useState(true)
    const [account, setAccount] = useState('')
    const [isConnected, setIsConnected] = useState(false)

    const sourceCode = data.contractData && data.contractData.sourceCode ? Object.entries(data.contractData.sourceCode) : []
    let readData: Array<any> = []
    let writeData: Array<any> = []
    let readProxyData: Array<any> = []
    let writeProxyData: Array<any> = []
    
    const abiContract = data.contractData.abiCode ? JSON.parse(data.contractData.abiCode) : null
    const contractData = abiContract ? getContract(abiContract, address ? address : '') : ''
    readData = abiContract && abiContract.filter((abi: any) => {
        return abi.outputs && abi.constant
    })
    writeData = abiContract && abiContract.filter((abi: any) => {
        return abi.outputs && !abi.constant
    })

    const abiProxyContract = data.contractData?.proxyTargetContractData?.abiCode ? JSON.parse(data.contractData?.proxyTargetContractData?.abiCode) : null
    const contractProxyData = abiProxyContract ? getContract(abiProxyContract, address ? address : '') : ''
    readProxyData = abiProxyContract && abiProxyContract.filter((abi: any) => {
        return abi.outputs && abi.constant
    })
    writeProxyData = abiProxyContract && abiProxyContract.filter((abi: any) => {
        return abi.outputs && !abi.constant
    })
    return (
        !data.contractData.sourceCode ?
            <>
                <NoteInfo>
                    <div className='flex-between'>
                        <div className='d-flex align-items-center'>
                            <img style={{height: '24px', width: '24px'}} src={icNothing} alt="" />
                            <div style={{marginLeft: '12px'}}>
                                <Text style={{display: 'block'}} strong>Are you the contract creator?</Text>
                                <Text style={{display: 'block'}}>
                                    Verify and publish your contract source code today.
                                </Text>
                            </div>
                        </div>
                        <Link 
                            style={{height: '40px', borderRadius: '1px',border: '1px solid',
                            borderColor: '#B47D000',
                            padding: '8px 12px',
                            background: '#fff',
                            color: '#8A6000',
                            textAlign: 'center',
                            }} 
                            to={`/contracts/verify/${address}`}>
                            Verify and Publish
                        </Link>
                    </div>
                </NoteInfo>
                <ContractSource height="20pc">
                    {data.code}
                </ContractSource>
            </>
            :
            <>
                <Tabs type="card" key="sub-tab">
                    <TabPane tab="Code" key="2.1">
                        <TitleHeaderBox>
                            <CheckStatus tokenStatus={data.tokenData} contractVerified={data.contractData?.abiCode ? true : false}/>
                        </TitleHeaderBox>
                        <Row gutter={24}>
                            <Col span={24} md={12}>
                                <Row gutter={24}>

                                    <Col span={24} md={5} lg={8}>
                                        <TxtColStyle className="col-txt-left">
                                            Contract Name:
                                        </TxtColStyle>
                                    </Col>
                                    <Col span={24} md={19} lg={16}>
                                        <TxtColStyle className="col-txt-right">
                                            {data.contractData.contractName}
                                        </TxtColStyle>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={24} md={5} lg={8}>
                                        <TxtColStyle className="col-txt-left">
                                            Compiler Version
                                        </TxtColStyle>
                                    </Col>
                                    <Col span={24} md={19} lg={16}>
                                        <TxtColStyle className="col-txt-right">
                                            {data.contractData.compiler}
                                        </TxtColStyle>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} md={12}>
                                <Row gutter={24}>
                                    <Col span={24} md={5} lg={8}>
                                        <TxtColStyle className="col-txt-left">
                                            Optimization:
                                        </TxtColStyle>
                                    </Col>
                                    <Col span={24} md={19} lg={16}>
                                        <TxtColStyle className="col-txt-right">
                                            {data.contractData.optimization ? "Yes" : "No"}
                                        </TxtColStyle>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {
                            sourceCode.length > 0 && sourceCode.map((value: any, i: any) => {
                                return (
                                    <SolidityRender key={`s-${i}`} data={value} />
                                )
                            })
                        }
                        {data.contractData.abiCode &&
                            <>
                                <TitleHeaderBox style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div><OrderedListOutlined /> Contract ABI</div>
                                    <Tooltip placement="top" title={copied ? "Copied" : "Copy to clipboard"}>
                                        <CopyToClipboard
                                            text={data.contractData.abiCode}
                                            onCopy={() => setCopied(true)}
                                            //@ts-ignore
                                            onMouseEnter={() => setCopied(false)}
                                        >
                                            <StyleCopyIcon style={{ marginRight: '10px' }}><span><img width="20" src={(iconCopy)} alt="icon copy" /></span></StyleCopyIcon>
                                        </CopyToClipboard>
                                    </Tooltip>
                                </TitleHeaderBox>

                                <ContractSource height="10pc">
                                    {data.contractData.abiCode}
                                </ContractSource>
                            </>
                        }
                        {data.code &&
                            <>
                                <TitleHeaderBox style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div><CodeTwoTone /> Contract Creation Code</div>
                                    <Tooltip placement="top" title={copied ? "Copied" : "Copy to clipboard"}>
                                        <CopyToClipboard
                                            text={data.code}
                                            onCopy={() => setCopied(true)}
                                            //@ts-ignore
                                            onMouseEnter={() => setCopied(false)}
                                        >
                                            <StyleCopyIcon style={{ marginRight: '10px' }}><span><img width="20" src={(iconCopy)} alt="icon copy" /></span></StyleCopyIcon>
                                        </CopyToClipboard>
                                    </Tooltip>
                                </TitleHeaderBox>
                                <ContractSource height="10pc">
                                    {data.code}
                                </ContractSource>
                            </>
                        }
                        {data.contractData.deployedBytecode &&
                            <>
                                <TitleHeaderBox style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <CodeTwoTone /> {isByteCode ? 'ByteCode' : 'Opcodes'}
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <SwitchButton
                                            onClick={() => {
                                                setIsByteCode(!isByteCode)
                                            }}>
                                            {`Switch to ${isByteCode ? 'Opcodes View' : 'ByteCode'}`}
                                        </SwitchButton>
                                        <Tooltip placement="top" title={copied ? "Copied" : "Copy to clipboard"}>
                                            <CopyToClipboard
                                                text={isByteCode ? data.contractData.deployedBytecode.object : data.contractData.deployedBytecode.opcodes}
                                                onCopy={() => setCopied(true)}
                                                //@ts-ignore
                                                onMouseEnter={() => setCopied(false)}
                                            >
                                                <StyleCopyIcon style={{ marginRight: '10px' }}><span><img width="20" src={(iconCopy)} alt="icon copy" /></span></StyleCopyIcon>
                                            </CopyToClipboard>
                                        </Tooltip>
                                    </div>
                                </TitleHeaderBox>
                                {isByteCode ?
                                    <ContractSource height="10pc">
                                        {data.contractData.deployedBytecode.object}
                                    </ContractSource>
                                    :
                                    <ContractSource height="10pc">
                                        {data.contractData.deployedBytecode.opcodes}
                                    </ContractSource>
                                }
                            </>
                        }
                        {data.contractData.deployedBytecode &&
                            <>
                                <TitleHeaderBox style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div>
                                        <HeatMapOutlined /> Deployed ByteCode Sourcemap
                                    </div>
                                    <Tooltip placement="top" title={copied ? "Copied" : "Copy to clipboard"}>
                                        <CopyToClipboard
                                            text={data.contractData.deployedBytecode.sourceMap}
                                            onCopy={() => setCopied(true)}
                                            //@ts-ignore
                                            onMouseEnter={() => setCopied(false)}
                                        >
                                            <StyleCopyIcon style={{ marginRight: '10px' }}><span><img width="20" src={(iconCopy)} alt="icon copy" /></span></StyleCopyIcon>
                                        </CopyToClipboard>
                                    </Tooltip>
                                </TitleHeaderBox>
                                <ContractSource height="6pc">
                                    {data.contractData.deployedBytecode.sourceMap}
                                </ContractSource>
                            </>
                        }
                    </TabPane>
                    <TabPane tab="Read" key="2.2">
                        {readData.length > 0 &&
                            <>
                                <TitleHeaderBox><FileTextOutlined />  Read Contract Information</TitleHeaderBox>
                                {
                                    readData.map((v: any, i: number) => {
                                        return (
                                            <Cards key={`r-content-${i}`}>
                                                <CardHeader><TextMain>{`${i + 1}. ${v.name}`}</TextMain></CardHeader>
                                                <CardContent>
                                                    <div>
                                                        {v.inputs.length < 1 ?
                                                            <ReadDataOutput outputs={v.outputs} name={v.name} contract={contractData} />
                                                            :
                                                            <FormReadRender data={v} name={v.name} contract={contractData} />
                                                        }
                                                    </div>
                                                </CardContent>
                                            </Cards>
                                        )
                                    })
                                }
                            </>
                        }
                    </TabPane>
                    <TabPane tab="Write" key="2.3">
                        {writeData.length > 0 &&
                            <>
                                <TitleHeaderBox><FileTextOutlined />  Write Contract Information</TitleHeaderBox>
                                <ModalWallet
                                    account={account}
                                    setAccount={setAccount}
                                    isConnected={isConnected}
                                    setIsConnected={setIsConnected}
                                />
                                {
                                    address && writeData.map((v: any, i: number) => {
                                        return (
                                            <Cards key={`w-content-${i}`}>
                                                <CardHeader><TextMain>{`${i + 1}. ${v.name} ${v.stateMutability === 'payable' ? '(payable)' : ''}`}</TextMain></CardHeader>
                                                <CardContent>
                                                    <FormWriteRender
                                                        data={v}
                                                        name={v.name}
                                                        abiCode={abiContract}
                                                        contractAddress={address}
                                                        account={account}
                                                        isConnected={isConnected}
                                                    />
                                                </CardContent>
                                            </Cards>
                                        )
                                    })
                                }
                            </>
                        }
                    </TabPane>
                    {readProxyData && 
                        <TabPane tab="Read as Proxy" key="2.4">
                        {readProxyData.length > 0 &&
                            <>
                                <TitleHeaderBox><FileTextOutlined />  Read Contract as Proxy</TitleHeaderBox>
                                <div style={{ marginBottom: "20px" }}>
                                    <Text>ABI for the implementation contract at <Link to={`/address/${data.contractData?.proxyTargetContract}#code`}><Text strong={true} italic={true}>{data.contractData?.proxyTargetContract}</Text></Link>{data.contractData?.proxyType ? `, using ${data.contractData?.proxyType} pattern` : ``}</Text>
                                </div>
                                {
                                    readProxyData.map((v: any, i: number) => {
                                        return (
                                            <Cards key={`r-content-${i}`}>
                                                <CardHeader><TextMain>{`${i + 1}. ${v.name}`}</TextMain></CardHeader>
                                                <CardContent>
                                                    <div>
                                                        {v.inputs.length < 1 ?
                                                            <ReadDataOutput outputs={v.outputs} name={v.name} contract={contractProxyData} />
                                                            :
                                                            <FormReadRender data={v} name={v.name} contract={contractProxyData} />
                                                        }
                                                    </div>
                                                </CardContent>
                                            </Cards>
                                        )
                                    })
                                }
                            </>
                        }
                        </TabPane>
                    }
                    {writeProxyData &&
                        <TabPane tab="Write as Proxy" key="2.5">
                        {writeProxyData.length > 0 &&
                            <>
                                <TitleHeaderBox><FileTextOutlined />  Write Contract as Proxy</TitleHeaderBox>
                                <div style={{ marginBottom: "20px" }}>
                                    <Text>ABI for the implementation contract at <Link to={`/address/${data.contractData?.proxyTargetContract}#code`}><Text strong={true} italic={true}>{data.contractData?.proxyTargetContract}</Text></Link> {data.contractData?.proxyType ? `, using ${data.contractData?.proxyType} pattern` : ``}</Text>
                                </div>
                                <ModalWallet
                                    account={account}
                                    setAccount={setAccount}
                                    isConnected={isConnected}
                                    setIsConnected={setIsConnected}
                                />
                                {
                                    address && writeProxyData.map((v: any, i: number) => {
                                        return (
                                            <Cards key={`w-content-${i}`}>
                                                <CardHeader><TextMain>{`${i + 1}. ${v.name} ${v.stateMutability === 'payable' ? '(payable)' : ''}`}</TextMain></CardHeader>
                                                <CardContent>
                                                    <FormWriteRender
                                                        data={v}
                                                        name={v.name}
                                                        abiCode={abiProxyContract}
                                                        contractAddress={address}
                                                        account={account}
                                                        isConnected={isConnected}
                                                    />
                                                </CardContent>
                                            </Cards>
                                        )
                                    })
                                }
                            </>
                        }
                        </TabPane>
                    }
                </Tabs>
            </>
    )
}

export default ContentOfTheContractTab