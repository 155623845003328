import { Input } from "antd";
import styled from "styled-components";

export const PositionStyled = styled.div`
  position: absolute;
  right: 400px;
  top: 190px;
  @media(max-width: 767px){
    position: static;
    .ant-form ant-form-vertical{
      justify-content: center;
    }
  }
`

export const InputStyled = styled(Input)`
  min-width: 250px;
  background-color: ${({theme})=> theme.bg1};
  padding-right: 5px;
  border: 1px solid ${({theme})=>theme.border};
  &:focus,&:active{
    outline: none;
    border: none;
  }
  .ant-input-suffix{
    margin-left: 0;
  }
  .ant-input{
    background-color: ${({theme})=> theme.bg1};
  }
`
export const ButtonSubmit = styled(Input)`
  cursor: pointer;
  color: #fff;
  background-color: ${({theme})=> theme.primary};
  fontSize: 12px;
  &:focus,&:active{
    outline: none!important;
    border: none!important;
    box-shadow: none!important;
  }
`
